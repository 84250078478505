<template>
   <div>
        <div class="columns mt-0">
            <div class="column is-4">
                <article class="panel ">
                    <p class="panel-heading p-2 bg-gradient-to-r from-orange-50 to-orange-200 hover:from-orange-100 hover:to-orange-300 is-size-7">
                        Blerje ditore
                    </p>
                    <table class="table is-striped is-hoverable is-fullwidth is-narrow is-size-7">
                        <tbody>
                            
                        </tbody>
                    </table>
                </article>

                <article class="panel ">
                    <p class="panel-heading p-2 bg-gradient-to-r from-emerald-50 to-emerald-200 hover:from-emerald-100 hover:to-emerald-300 is-size-7">
                        Blerje Mujore
                    </p>
                    <table class="table is-striped is-hoverable is-fullwidth is-narrow is-size-7">
                        <tbody>
                        </tbody>
                    </table>
                </article>
                
            </div>
            <div class="column is-8">
                <article class="panel ">
                    <p class="panel-heading p-2 bg-gradient-to-r from-sky-50 to-sky-200 hover:from-sky-100 hover:to-sky-300 is-size-7">
                        Blerje ditore
                    </p>
                    <table class="table is-striped is-hoverable is-fullwidth is-narrow is-size-7">
                        <tbody>
                        </tbody>
                    </table>
                </article>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>