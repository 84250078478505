<!-- ################################################## Customers -->
<template>
<div v-if="am_allowed('customer.can_see_customers')">
  <div class="columns" :class="{ 'half-height': selected_item.id }">
    <div class="column is-flex is-flex-direction-column pb-0">
      <div class="level p-0 m-0">
        <div class="level-left">
          <grid-col-manage
            :columnDefs="columnDefs.filter(f => !['action', 'name'].includes(f.field) )"
            :hiden_columns="hiden_columns"
            @toggle_column="toggle_column"
          ></grid-col-manage>
          <div class="level-item">Kliente List</div>
          <input class="input" style="margin: 5px; width: 300px" 
            type="text" placeholder="Kerko..." 
            v-model="global_search_filter" @input="onSearchEntity">
        </div>
        <div class="level-right">
          <div class="level">
            <a v-if="am_allowed('customer.can_create_customers')" @click.prevent="create_customer">
              <i class="fa fa-plus"></i>
            </a>
          </div>
        </div>
      </div>
      <ag-grid-vue
        style="width: 100%; min-height: 40vh; height:80vh;"
        class="ag-theme-balham is-flex-grow-1"
        :columnDefs="columnDefs"
        :rowData="rowData.value"
        :rowSelection="rowSelection"
        :context="context"
        :animateRows="true"
        :localeText="localeText"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </div>
    <div v-if="form_edit || form_create"
      :class="{ 'overfllow-y-scroll': selected_item.id }" class="column is-5 border-b">
      <DaForm v-if="form_create" @submitted="submitCustomer" :form="form_create" :closes_form="true" @close="form_create = null"
        :is_overflow="selected_item.id ? true : false">
      </DaForm>
      <DaForm v-if="form_edit" @submitted="submitCustomer" :form="form_edit" :closes_form="true" @close="form_edit = null"
        :is_overflow="selected_item.id ? true : false">
      </DaForm>
    </div>
  </div>
  <div v-if="selected_item.id" class="">
    <div class="columns border-t-2 border-slate-200 mt-3">
      <div class="column is-4">
        <customer-details :selected_item="selected_item"></customer-details>
      </div>
      <div class="column is-8">
        <div class="">
          <div class="tabs is-fullwidth is-boxed is-size-7">
            <ul>
              <li :class="{'is-active': show_tab == 1}"><a @click.prevent="show_tab = 1">Shitje</a></li>
              <li :class="{'is-active': show_tab == 2}"><a @click.prevent="show_tab = 2">Blerje</a></li>
              <li :class="{'is-active': show_tab == 3}"><a @click.prevent="show_tab = 3">Porosi</a></li>
              <li :class="{'is-active': show_tab == 4}"><a @click.prevent="show_tab = 4">Rezervime</a></li>
              <li :class="{'is-active': show_tab == 5}"><a @click.prevent="show_tab = 5">Garanci</a></li>
            </ul>
          </div>
        </div>
        <div class="px-2" style="border:solid 1px #dbdbdb; border-width:0px 1px;">
          <div v-if="show_tab == 1"> 
            <customer-sales></customer-sales>
          </div>
          <div v-if="show_tab == 2"> 
            <customer-bookings></customer-bookings>
          </div>
          <div v-if="show_tab == 3">
            <customer-obligations></customer-obligations>
          </div>
          <div v-if="show_tab == 4"> 
            <customer-transport></customer-transport>
          </div>
          <div v-if="show_tab == 5">
            <customer-warranty></customer-warranty>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import locale from "@/components/ag-grid/locale/al/al.js"
import createCustomer from "./forms/create";
import editCustomer from "./forms/edit";
import CategoriesServices from "@/services/Category";
import CustomersServices from "@/services/Customer";
import customerSales from "./components/customer-sales";
import customerBookings from "./components/customer-bookings";
import customerObligations from "./components/customer-obligations";
import customerTransport from "./components/customer-transport";
import customerWarranty from "./components/customer-warranty";
import customerDetails from "./components/customer-details";
import socket from '@/socket.js';
import { onUnmounted, reactive, inject } from 'vue'
import permissionMixin from '@/mixins/permissions-mixin.js'
import gridColManage from "@/components/grid_col_manage.vue";

export default {
  mixins: [permissionMixin],
  components: {
    AgGridVue,
    DaForm,
    customerDetails,
    customerSales,
    customerBookings,
    customerObligations,
    customerTransport,
    customerWarranty,
    gridColManage 
  },
  setup(){
    let rowData = reactive([])
    const toast = inject('toast')
    socket.connect()
    socket.on("welcome", data => {
      console.log("welcome", data)
    })
    socket.on('propagate', data => {
      if(data.entity == 'customers') {
        if(data.action == 'create') {
          var a = rowData.value
          a.push(data.data)
          rowData.value = [...a]
          toast.success('Klienti u krijua <i>nga ' + data.created_by.name + '</i>')
        }
        if(data.action == 'edit') {
          rowData.value = [...rowData.value.map(item => {
            if(item.id == data.data.id){
              return data.data
            }
            return item
          })]
          toast.success('Klienti u modifikua <i>nga ' + data.created_by.name +'</i>')
        }
        if(data.action == 'delete') {
          rowData.value = [...rowData.value.filter(item => {
            return item.id != data.data.id
          })]
        }
      }
    })
    onUnmounted(() => {
      socket.off('welcome')
      socket.off('propagate')
      socket.disconnect()
    })
    return {
      rowData
    }
  },
  beforeMount() {
    this.context = { componentParent: this }
  },
  data() {
    return {
      global_search_filter : "",
      context              : null,
      selectedCustomer     : {},
      form_create          : null,
      form_edit            : null,
      gridApi   : null,
      columnApi : null,
      defaultColDef: {
        flex: 1,
        minWidth: 100,
      },
      rowSelection        : "single",
      details_types       : [],
      customer_categories : [],
      localeText          : null,
      show_tab: 1,
      selected_item: {},
      attributes_per_item: {},
      hiden_columns: ['region_name', 'dob', 'registered_at', 'personal_id', 'registration_channel', 'district_name', 'active']
    };
  },
  
  computed: {
     columnDefs(){
      var columns = [
        { headerName: "Kategoria", field: "type_id_name", filter: true,
        cellRenderer: function(params) {
          let r = ""
          if(params.data.type_id == "EMPTY-STRING")
            r = `<i class="type_id">[SKA KATEGORI]</i>`
          else
            r = params.data.type_id_name
          return r
        },
        floatingFilter: true, sortable: true, hide: this.hiden_columns.includes("type_id_name")},
        { headerName: "Kodi", field: "code", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("code")},
        { headerName: "Emri", field: "name", pinned: 'left', filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("name")},
        { headerName: "Data e regjistrimit", field: "registered_at", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("registered_at")},
        { headerName: "Regjistruar ne:", field: "registration_channel", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("registration_channel")},
        { headerName: "ID Personale", field: "personal_id", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("personal_id")},
        { headerName: "Datelindja", field: "dob", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("dob")},
        { headerName: "Adresa", field: "location", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("location")},
        { headerName: "Qyteti", field: "city_name", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("city_name")},
        { headerName: "Rajoni", field: "region_name", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("region_name")},
        { headerName: "Rrethi", field: "district_name", filter: true, floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("district_name")},
        { headerName: "Aktiv", field: "active", filter: true,
        cellRenderer: function(params) {
          let r = ""
          if(params.data.active == '0')
              r = `<i class="fa-regular fa-circle-xmark fa-lg"></i>`
          else if(params.data.active == '1')
              r = `<i class="fa-regular fa-circle-check fa-lg"></i>`
          return r
        },
        floatingFilter: true, sortable: true, resizable: true, hide: this.hiden_columns.includes("active")},
        { headerName: "Action", pinned: 'right', cellRenderer: ButtonsCell, cellRendererParams: this.context, resizable: true, field: 'action'},

      ]
      return columns
     } 
  },
  methods: {
    toggle_column(column) {
      if (this.hiden_columns.includes(column)) {
        this.hiden_columns = [...this.hiden_columns.filter(item => item != column)]
      } else {
        this.hiden_columns.push(column)
      }
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    onSearchEntity() {
      this.gridApi.setQuickFilter(this.global_search_filter)
    },
    async open(mode, customer) {
      if(mode == '1'){ 
        if(this.am_allowed('customer.can_edit_customers')){
          // edit mode
          /* creates a form for edit of the customer                      */
          this.form_create = null;
          if (customer) {
  
              /* create edit form for the customer                        */
              setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
              this.form_edit = {loading:true}
              this.form_edit = await editCustomer( customer);
              setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);

  
              this.selectedCustomer = customer;
          }
        } else {
          this.$toast.error('Nuk keni te drejta te modifikoni klientie.')
        }
      }
      if(mode == '0') {
        if(this.am_allowed('customer.can_see_customers_details')) {
          this.selected_item = (await CustomersServices.get({guid: customer.guid}))
          setTimeout(async () => {
            let selected_category = {}
            selected_category[customer.type_id] = true
            this.attributes_per_item = await CategoriesServices.getCategoryAttributes(selected_category, customer)
          })
        } else {
          this.$toast.error('Nuk keni te drejta per te hapur te dhenat klientit.')
        }
      }
    },
    async create_customer() {
      this.form_edit = null;
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
      this.form_create = {loading: true}
      this.form_create = await createCustomer()
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    async submitCustomer(f) {
      var notify_text = ""
      if (this.form_create != null){
        let customer = await CustomersServices.createCustomer(f)
        notify_text = "Klienti u krijua me sukses!"
        var a = this.rowData.value
        a.push(customer);
        this.rowData.value = [...a]
        socket.emit("propagate", {entity: "customers", action: "create", data: customer})
        this.form_create = null;
        this.$root.selected_one = {};
      }
      else {
        f.guid = this.selectedCustomer.guid;
        let customer = await CustomersServices.updateCustomer(f);
        this.rowData.value = [
          ...this.rowData.value.map((c) => {
            if (c.guid == customer.guid) c = { ...customer };
            return c;
          }),
        ];
        notify_text = "Klienti u modifikua me sukses!"
        socket.emit("propagate", {entity: "customers", action: "edit", data: customer})
        this.form_edit = null;
        this.$root.selected_one = {};
      }
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 100);
      this.$toast.success(notify_text)
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.gridColumnApi = params.columnApi;
    },
  },
  async created() {
    if(!this.am_allowed('customer.can_see_customers')){
      window.location.href = '/home'
    }
    this.localeText = locale.locale_al()
    this.rowData.value = await CustomersServices.getCustomers();
    this.$root.setCurrentRoute("kliente-lista");
    this.gridApi.sizeColumnsToFit();
  },
  onBeforeDestroy() {
    socket.disconnect()
  }
};
</script>

<style>
  .type_id {
    color: rgb(235, 8, 8);
  }
  .half-height {
    height: 50vh;
  }
  .overfllow-y-scroll {
    overflow-y: scroll;
    position: relative;
  }
</style>
