<template>
    <div>
        <p class="is-size-5 has-text-centered"> 
            <!-- <span class="is-pulled-left"> Info </span> -->
            <span v-if="selected_item.is_business">
                {{selected_item.business_name}}
            </span>
            <span v-else>
                {{selected_item.firstname}} {{selected_item.lastname}}
            </span>
        </p>
        <div class="mb-4 is-size-7">
            <div class="is-flex is-justify-content-space-between mb-1 border-b-2 border-slate-100">
                <div v-if="selected_item.type_id">
                    <small class="text-slate-400">Kategoria</small> 
                    <p>Cat1/Cat2/Cat3/Cat child</p>
                </div>
                <div v-if="selected_item.code" class="has-text-right">
                    <small class="text-slate-400">Kodi</small> 
                    <p># {{selected_item.code}}</p>
                </div>
            </div>
            <!-- <div class="border-b-2 border-slate-100">
                <small class="text-slate-400">Pershkrimi</small> 
                <p>{{selected_item.description}}</p>
            </div> -->
        </div>
        <table class="table is-striped is-hoverable is-fullwidth is-narrow is-bordered is-size-7">
            <thead>
                <tr>
                    <th>Specifika</th>
                    <th>Vlera</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(attribute, index) in selected_item.attributes" :key="index">
                    <td>{{attribute.attribute_name}}</td>
                    <td>{{attribute.attribute_value}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['selected_item']

}
</script>

<style>

</style>