var CryptoJS = require("crypto-js");
export default {
    data() {
        return {
            permissions_modules: [],
            // permissions_modules: [
            //     {
            //         module_name: "product", id: 0,
            //         module_title: "Produkte",
            //         permissions: [
            //           /* */  {name: 'can_see_product', short_description: 'Sheh produkte', description: '-', value: 0, original_value: 0},
            //           /* */  {name: 'can_create_product', short_description: 'Krijon produkte', description: '-', value: 0, original_value: 0},
            //           /* */  {name: 'can_edit_product', short_description: 'Modifikon produkte', description: '-', value: 0, original_value: 0},
            //           /* */  {name: 'can_see_product_details', short_description: 'Sheh detajet e produktit', description: '-', value: 0, original_value: 0},

            //            /* */ {name: 'can_see_product_category', short_description: 'Sheh kategori', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_create_product_category', short_description: 'Krijon kategori', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_product_category', short_description: 'Modifikon kategori', description: '-', value: 0, original_value: 0},

            //            /* */ {name: 'can_see_product_attributes', short_description: 'Sheh atribute', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_create_product_attributes', short_description: 'Krijon atribute', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_product_attributes', short_description: 'Modifikon atribute', description: '-', value: 0, original_value: 0},

            //            /* */ {name: 'can_see_product_materials', short_description: 'Sheh materiale', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_create_product_materials', short_description: 'Krijon materiale', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_product_materials', short_description: 'Modifikon materiale', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_see_material_details', short_description: 'Sheh detajet e materialit', description: '-', value: 0, original_value: 0},

            //            /* */ {name: 'can_see_product_services', short_description: 'Sheh sherbime', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_create_product_services', short_description: 'Krijon sherbime', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_product_services', short_description: 'Modifikon sherbime', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_see_service_details', short_description: 'Sheh detajet e sherbimit', description: '-', value: 0, original_value: 0},
            //         ] 
            //     },
            //     {
            //         module_name: "customer", id: 0,
            //         module_title: "Kliente",
            //         permissions: [
            //            /* */ {name: 'can_see_customers', short_description: 'Sheh klientet', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_create_customers', short_description: 'Krijon klient', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_customers', short_description: 'Modifikon klient', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_see_customers_details', short_description: 'Sheh detajet e klientit', description: '-', value: 0, original_value: 0},

            //            /* */ {name: 'can_see_customer_category', short_description: 'Sheh kategori', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_create_customer_category', short_description: 'Krijon kategori', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_customer_category', short_description: 'Modifikon kategori', description: '-', value: 0, original_value: 0},

            //            /* */ {name: 'can_see_customer_attributes', short_description: 'Sheh atribute', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_create_customer_attributes', short_description: 'Krijon atribute', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_customer_attributes', short_description: 'Modifikon atribute', description: '-', value: 0, original_value: 0},

            //         ]
            //     },
            //     {
            //         module_name: "transactions", id: 0,
            //         module_title: "Transaksione",
            //         permissions: [
            //            /* */ {name: 'can_see_warehouses', short_description: 'Sheh magazina', description: '-', value: 0, original_value: 0},
            //             /**/ {name: 'can_create_warehouses', short_description: 'Krijon magazina', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_warehouses', short_description: 'Modifikon magazina', description: '-', value: 0, original_value: 0},
            //             {name: 'can_see_warehouses_details', short_description: 'Sheh detajet e magazines', description: '-', value: 0, original_value: 0},

            //            /* */ {name: 'can_see_pos', short_description: 'Sheh Pikat e shitjes', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_create_pos', short_description: 'Krijon Pikat e shitjes', description: '-', value: 0, original_value: 0},
            //            /* */ {name: 'can_edit_pos', short_description: 'Modifikon Pikat e shitjes', description: '-', value: 0, original_value: 0},
            //             {name: 'can_see_pos_details', short_description: 'Sheh detajet e pikes se shitjes', description: '-', value: 0, original_value: 0},
            //         ]
            //     },
            //     {
            //         module_name: "draft", id: 0,
            //         module_title: "Draft",
            //         permissions: [
            //             {name: 'can_create', short_description: 'Krijon draft', description: '-', value: 0, original_value: 0},
            //             {name: 'can_request_discount', short_description: 'Kerkon cmim', description: '-', value: 0, original_value: 0},
            //             {name: 'can_confirm_discount', short_description: 'Konfirmon kerkese cmimi', description: '-', value: 0, original_value: 0},
            //             {name: 'can_manage_others_draft', short_description: 'Menaxhon draftet e te tjereve', description: '-', value: 0, original_value: 0},
            //             {name: 'can_edit', short_description: 'Modifikon draft', description: '-', value: 0, original_value: 0},
            //             {name: 'can_see', short_description: 'Sheh draft', description: '-', value: 0, original_value: 0},
            //             {name: 'can_delete', short_description: 'Fshin draft', description: '-', value: 0, original_value: 0},
            //         ]
            //     },
            //     {
            //         module_name: "order", id: 0,
            //         module_title: "Porosi",
            //         permissions: [
            //             /**/ {name: 'can_see', short_description: 'Sheh porosite', description: '-', value: 0, original_value: 0},
            //             /**/ {name: 'can_create', short_description: 'Krijon porosi', description: '-', value: 0, original_value: 0},
            //             /**/ {name: 'can_edit', short_description: 'Modifikon porosi', description: '-', value: 0, original_value: 0},
            //             /**/ {name: 'can_delete', short_description: 'Fshin porosi', description: '-', value: 0, original_value: 0},
            //         ]
            //     },
            //     {
            //         module_name: "user_roles", id: 0,
            //         module_title: 'Te drejtat e perdoruesve',
            //         permissions: [
            //             {name: 'can_see_user_roles', short_description: 'Mund te shoh te drejtat e perdoruesve', description: '', value: 0, original_value: 0},
            //             {name: 'can_see_user', short_description: 'Sheh perdoruesit', description: '', value: 0, original_value: 0},
            //             {name: 'can_create_user', short_description: 'Krijon perdorues', description: '', value: 0, original_value: 0},
            //             {name: 'can_edit_user', short_description: 'Modifikon perdorues', description: '', value: 0, original_value: 0},

            //             {name: 'can_create_role', short_description: 'Krijon role', description: '', value: 0, original_value: 0},
            //             {name: 'can_edit_role', short_description: 'Modifikon role', description: '', value: 0, original_value: 0},
            //             {name: 'can_edit_permissions', short_description: 'Percakton te drejta', description: '', value: 0, original_value: 0},

            //             {name: 'can_see_pos', short_description: 'Sheh pikat e shitjes', description: '', value: 0, original_value: 0},
            //             {name: 'can_assign_pos', short_description: 'Percakton perdorues ne pike shitjesh', description: '', value: 0, original_value: 0},
            //             {name: 'can_delete_pos', short_description: 'Heq perdoruesin nga pika e shitjes', description: '', value: 0, original_value: 0},

                        
            //         ]
            //     }
            // ],
            user_role_permissions_modules: []
        }
    },
    methods: {
        test() {
            console.log("from mixin")
        },
        am_allowed(module){
            // Decrypt localStorage.permissions
            var user = JSON.parse(localStorage.user)
            var bytes  = CryptoJS.AES.decrypt(localStorage.permissions, localStorage.token);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            var user_permissions = JSON.parse(originalText)
            let user_role_permissions_modules = this.permissions_merge(user_permissions, 0, user.type_id)
            var module_parts = module.split('.')
            var is_allowed = false
            if(user_role_permissions_modules.length > 0)
                user_role_permissions_modules.map(m => {
                    if(m.module_name == module_parts[0]){
                        m.permissions.map(p => {
                            if(p.name == module_parts[1]) is_allowed = p.value == 1
                            return p
                        })
                        return m
                    }
                })
            return is_allowed
        },
        permissions_merge(permissions_list, change, is_admin = 0){
            let user_role_permissions_modules = [] 
            // if(permissions_list.length > 0)
                this.permissions_modules.map(pm => {
                    let module_found = false
                    if(is_admin != -1)
                    permissions_list.map(pl => {
                        if(pl.name == pm.module_name){
                            module_found = true
                            let permermission_details = {
                                id: pl.id,
                                module_name: pm.module_name,
                                module_title: pm.module_title,
                                is_collapsed: true,
                                permissions: pm.permissions.map((p, index) => {
                                    return {
                                        name: p.name,
                                        short_description: p.short_description,
                                        description: p.description,
                                        value: parseInt(pl.permissions_values[index]),
                                        original_value: parseInt(pl.permissions_values[index])
                                    }
                                })
                            }
                            user_role_permissions_modules.push(permermission_details)
                        }
                    })
                    if(!module_found) {
                        let permis = {...pm}
                        if(is_admin == -1) {
                            permis.permissions = [...permis.permissions.map(mp => {
                                mp.value = 1
                                mp.original_value = mp.value
                                return mp
                            })]
                        }
                        user_role_permissions_modules.push({...permis})
                    }
                })
            // if(user_role_permissions_modules.length == 0) this.user_role_permissions_modules = [...this.permissions_modules]

            if(change == 1) this.user_role_permissions_modules = [...user_role_permissions_modules]
            else return user_role_permissions_modules
            // BUG: if change == 0
        },
    },
    created(){
        if(localStorage.user_roles){
            this.permissions_modules = JSON.parse(localStorage.user_roles)
        }
    }
}