<template>
  <div>
    <div class="dropdown is-hoverable mr-2">
      <div class="dropdown-trigger">
        <button class="button is-small" aria-haspopup="true" aria-controls="dropdown-menu4">
          <span>
            <i class="fa fa-cog fa-xs"></i>
            <!-- table fa icon -->
            <i class="fa fa-table fa-xs ml-2"></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu4" role="menu">
        <div class="dropdown-content">
          <a @click.prevent="$emit('toggle_column', column.field)"
            v-for="column in columnDefs.filter(column => column.field != 'actions')" :key="column.field"
            class="dropdown-item">
            <i class="fa fa-eye mr-2"
              :class="[hiden_columns.includes(column.field) ? 'text-gray-100' : 'text-green-700']"></i>
            <span>{{ column.headerName }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['columnDefs', 'hiden_columns']
}
</script>

<style>
#dropdown-menu4 {
  height: 55vh;
  overflow-y: scroll;
  border: solid 1px #dedede;
}
</style>
