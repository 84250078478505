import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";
import PointsOfSaleServices from "@/services/PointOfSale";
import CustomersServices from "@/services/Customer";

export default async function editCustomer(c) {
  var customer_categories = await CategoriesServices.getCategories("customers");
  var customers_types = helpers.toTree(customer_categories, "id")

  var city = await PointsOfSaleServices.getCities()
  var region = await CustomersServices.getRegions()
  var district = await CustomersServices.getDistricts()

  let selected_category = {};
  selected_category[c.type_id] = true;
  var category_attributes_list = await CategoriesServices.getCategoryAttributes(selected_category, c)
  var category_attributes = helpers.create_attributes_field(category_attributes_list, selected_category)
  var referrals = await CustomersServices.getCustomerRefferrals()

  var customers_selected_types = {}
  customers_selected_types[c.type_id] = c.type_id_name
  return {
    name: 'Modifiko klient',
    submit: {
      label: 'Modifiko',
      action: 'submitCustomer'
    },
    fields: [
      [
        {
          value: c.code,
          name: 'code',
          label: 'Kodi',
          type: 'text-field',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme',
          style: "min-width: 30%",
          unique: {
            model: 'customer',
            field: 'code',
            where: [
              {
                column: 'guid',
                condition: '!=',
                value: c.guid
              }
            ]
          },
          left_icon: 'fa-solid fa-hashtag',
        },
        {
          value: c.firstname,
          name: 'firstname',
          label: 'Emri',
          type: 'text-field',
          style: 'min-width: 30%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: c.lastname,
          name: 'lastname',
          label: 'Mbiemri',
          type: 'text-field',
          style: 'min-width: 30%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: c.active,
          name: 'active',
          label: 'Aktiv',
          type: 'checkbox-field',
          style: "min-width: 2%"
        },
      ],
      [
        {
          value: '',
          name: 'phone',
          label: 'Telefoni',
          type: 'text-field',
          style: 'min-width: 100%'
        },
      ],
      [
        {
          value: c.personal_id,
          name: 'personal_id',
          label: 'ID Personale',
          type: 'text-field',
          style: 'min-width: 45%',

        },
        {
          value: c.dob,
          name: 'dob',
          label: 'Datelindja',
          type: 'date-field',
          style: 'min-width: 50%'
        },


      ],
      [
        // {
        // 	value: c.registered_at,
        // 	name: 'registered_at',
        // 	label: 'Data e regjistrimit',
        // 	type: 'datetime',
        // 	required: true,
        // 	required_text: 'Kjo fushe eshte e detyrueshme',
        // 	style: "min-width: 45%"
        // },
        {
          value: c.registration_channel,
          options: [
            // { id: '', label: 'Zgjidh...' },
            { id: 'web', label: 'Web' },
            { id: 'pos', label: 'POS' },
            { id: 'agent', label: 'Agjent' },
            { id: 'customer', label: 'Klient' },
            { id: 'other', label: 'Tjeter' }
          ],
          name: 'registration_channel',
          label: 'Kanali i regjistrimit',
          type: 'select-field',
          style: 'min-width: 50%',
          required: false,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },

        {
          value: c.referred_by,
          name: 'referred_by',
          label: 'Referuar nga',
          type: 'select-field',
          style: 'min-width: 50%',
          options: referrals,
        },
      ],
      [
        {
          value: c.sales_type,
          options: [
            { id: 1, label: 'Pakice' },
            { id: 2, label: 'Shumice' },
          ],
          name: 'sales_type',
          label: 'Kanali i regjistrimit',
          type: 'select-field',
          style: 'min-width: 50%',
          required: false,
        },
        {
          value: c.is_default,
          name: 'is_default',
          label: 'Klient gjnerik i sistemit',
          type: 'checkbox-field',
        },
      ],
      [
        { 
          value: c.other_referral,
          name: 'other_referral', 
          label: 'Kanali tjeter',
          type: 'text-field',
          style: 'min-width: 100%',
          vif: [ 
            {field: 'referred_by', value: 6}
          ]
        }
      ],
      [
        {
          value: c.location,
          name: 'location',
          label: 'Adresa',
          type: 'text-field',
          style: 'min-width: 45%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: c.city,
          name: 'city',
          label: 'Qyteti',
          type: 'select-field',
          options: city,
          selected_options: [],
          style: 'min-width: 45%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        }
      ],
      [
        {
          value: c.region,
          name: 'region',
          label: 'Rajoni',
          type: 'select-field',
          options: region,
          selected_options: [],
          style: 'min-width: 45%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: c.district,
          name: 'district',
          label: 'Rrethi',
          type: 'select-field',
          options: district,
          selected_options: [],
          style: 'min-width: 45%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        }
      ],
      [
        {
          name: 'type_id',
          label: 'Kategoria',
          type: 'tree-select',
          style: 'flex: 1',
          mode: 'single',
          options: customers_types,
          selected_options: customers_selected_types,
        },
        // {
        // 	value: c.is_business, 
        // 	name: 'is_business', 
        // 	label: 'Eshte biznes', 
        // 	type: 'checkbox-field', 
        // },
        // {
        // 	value: c.business_name, 
        // 	name: 'business_name', 
        // 	label: 'Emri i biznesit', 
        // 	type: 'text-field', 
        // 	style: 'min-width: 45%',
        // 	required_text: 'Kjo fushe eshte e detyrueshme',
        // 	vif: [
        // 		{ field: 'is_business', value_not: 0}
        // 	]
        // },
      ],

      [
        {
          data: [],
          name: 'attributes',
          label: 'Atribute',
          type: 'attributes',
          options: category_attributes,
          style: 'min-width: 45%',
          depends_on: 'type_id',
          api_call: 'category/attributes'
        },

      ]

    ]
  }
}
